import {
  Typography,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Button,
  Box,
  TextField,
  Grid,
} from "@mui/material";

import SignatureCanvas from "react-signature-canvas";
import React, { useRef, useState, useCallback } from "react";
import "./stylefirma.css";
import api from "../components/utils/api";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateField } from "@mui/x-date-pickers/DateField";
import SendIcon from "@mui/icons-material/Send";
import swal from "sweetalert";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useNavigate } from "react-router-dom";
import "dayjs/locale/es";
import Loading from "../components/Loading/Loading";

import { uuidv4 } from "../components/Id/uuidv4";
import FileUploader from "../components/FileUploader/FileUploader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { styleBox } from "../components/utils/Stylebox";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("es");

export default function NuevoServicio() {
  const userToken = localStorage.getItem("user-token");
  const user = localStorage.getItem("nombre-Completo");
  const UrlServicios = api.urlAPI + "servicios";
  const padRef = useRef(null);
  const [canvas, setCanvas] = useState("");
  const [canvasVisibility, setCanvasVisibility] = useState(false);
 
  const clearSignatureCanvas = useCallback(() => {
    padRef?.current?.clear();
    setCanvas("");
    setCanvasVisibility(false);
    formik.setFieldValue("canvas", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetCanvas = useCallback(() => {
    const canvas = padRef?.current?.toDataURL();

    setCanvas(canvas);
    formik.setFieldValue("canvas", canvas);
    //setCanvasVisibility(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const [tecnico, setTecnico] = React.useState(user);
  const [horaFin, setHoraFin] = React.useState(dayjs());
  const [horaInicio, setHoraInicio] = React.useState(dayjs());
  const [loading, setLoading] = useState(false);
  const [idInterno, setIdInterno] = useState(uuidv4());
  const [fecha, setFecha] = React.useState(dayjs().tz(dayjs.tz.guess()));
  const [imgServicio, setImgServicio] = useState([]);
  const [loadFile, setLoadFile] = useState(false);

  const [lines, setLines] = useState([{ id: 1 }]);
  const mensajeCampo = "El campo es requerido";
  const mensajeCampoFlag = "Debes marcar al menos un flag o el campo otros";
  const validationSchema = Yup.object({
    cliente: Yup.string().required(mensajeCampo),
    contacto: Yup.string().required(mensajeCampo),
    direccion: Yup.string()
      .required(mensajeCampo)
      .matches(
        /^(?=.*[a-zA-Z\s])(?=.*\d).*$/,
        "El campo debe contener letras y números"
      ),
    localidad: Yup.string().required(mensajeCampo),
    provincia: Yup.string().required(mensajeCampo),
    modelo: Yup.string().required(mensajeCampo),
    chasis: Yup.string().required(mensajeCampo),
    motor: Yup.string().required(mensajeCampo),
    falla: Yup.string().required(mensajeCampo),
    servicioRealizado: Yup.string().required(mensajeCampo),
    km: Yup.number().required(mensajeCampo),
    canvas: Yup.string().required(mensajeCampo),
    aclaracionFirma: Yup.string().required(mensajeCampo),
    //telefono: Yup.string()
    //.matches(/^[0-9]{10}$/, 'Por favor, introduce un número de teléfono válido'),
    //mail: Yup.string().email('Por favor, introduce una dirección de correo electrónico válida'),
    flagReparacion: Yup.boolean().test(
      "conditional-validation",
      mensajeCampoFlag,
      function (value) {
        const otros = this.resolve(Yup.ref("otros"));
        const flagServicio = this.resolve(Yup.ref("flagServicio"));
        const flagGarantia = this.resolve(Yup.ref("flagGarantia"));

        if (!otros && !flagServicio && !flagGarantia) {
          return (
            value ||
            this.createError({ message: mensajeCampoFlag, path: this.path })
          );
        }

        return true;
      }
    ),
    flagServicio: Yup.boolean().test(
      "conditional-validation",
      mensajeCampoFlag,
      function (value) {
        const otros = this.resolve(Yup.ref("otros"));
        const flagReparacion = this.resolve(Yup.ref("flagReparacion"));
        const flagGarantia = this.resolve(Yup.ref("flagGarantia"));

        if (!otros && !flagReparacion && !flagGarantia) {
          return (
            value ||
            this.createError({ message: mensajeCampoFlag, path: this.path })
          );
        }

        return true;
      }
    ),
    flagGarantia: Yup.boolean().test(
      "conditional-validation",
      mensajeCampoFlag,
      function (value) {
        const otros = this.resolve(Yup.ref("otros"));
        const flagReparacion = this.resolve(Yup.ref("flagReparacion"));
        const flagServicio = this.resolve(Yup.ref("flagServicio"));

        if (!otros && !flagReparacion && !flagServicio) {
          return (
            value ||
            this.createError({ message: mensajeCampoFlag, path: this.path })
          );
        }

        return true;
      }
    ),
    otros: Yup.string().test(
      "conditional-validation",
      mensajeCampoFlag,
      function (value) {
        const flagReparacion = this.resolve(Yup.ref("flagReparacion"));
        const flagServicio = this.resolve(Yup.ref("flagServicio"));
        const flagGarantia = this.resolve(Yup.ref("flagGarantia"));

        if (!flagReparacion && !flagServicio && !flagGarantia) {
          return (
            value ||
            this.createError({ message: mensajeCampoFlag, path: this.path })
          );
        }

        return true;
      }
    ),
    horometro: Yup.string().required(mensajeCampo),
    dni: Yup.number().required(mensajeCampo),
  });

  const preValidation = () => {
    if (loadFile) {
      swal("Revisa el formulario", "Tines archivos selecionados pendientes de cargar", "error");
      return;
    }
    if (!formik.values.canvas) {
      swal("Revisa el formulario", "La firma no ha sido guardada", "error");
      return;
    }
    formik.handleSubmit();
    // Validar si hay errores en Formik
    if (Object.keys(formik.errors).length > 0) {
      swal(
        "Revisa el formulario",
        "Faltan completar campos obligatorios",
        "error"
      );
      return;
    }
   
  };

  const formik = useFormik({
    initialValues: {
      cliente: "",
      contacto: "",
      direccion: "",
      localidad: "",
      provincia: "",
      modelo: "",
      chasis: "",
      motor: "",
      falla: "",
      servicioRealizado: "",
      tecnico: tecnico,
      km: "",
      canvas: "",
      aclaracionFirma: "",
      flagReparacion: false,
      flagServicio: false,
      flagGarantia: false,
      otros: "",
      loadFile: "",
      horometro: "",
      dni: "",
      idInterno:idInterno
      //mail: '',
      //telefono: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      enviarFormulario(values);
    },
  });

  const enviarFormulario = async (values) => {
    try {
      const confirmacion = await swal({
        title: "¿Estás seguro?",
        text: "Se enviará el documento que cargaste, por favor verifica los datos!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (!confirmacion) {
        return; // No se realiza ninguna acción adicional si el usuario cancela
      }
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          cliente: values.cliente,
          fecha: fecha,
          contacto: values.contacto,
          direccion: values.direccion,
          localidad: values.localidad,
          dni: values.dni,
          //mail: values.mail,
          provincia: values.provincia,
          //telefono: values.telefono,
          modelo: values.modelo,
          chasis: values.chasis,
          motor: values.motor,
          tipoServicioReparacion: values.flagReparacion,
          tipoServicioServicio: values.flagServicio,
          tipoServicioGarantia: values.flagGarantia,
          tipoServicioOtros: values.otros,
          descripcionFalla: values.falla,
          servicio: values.servicioRealizado,
          kmRecorridos: values.km,
          horaInicio: horaInicio,
          horaFin: horaFin,
          tecnico: values.tecnico,
          firmaTecnico: localStorage.getItem("firma"),
          firmaCliente: values.canvas,
          aclaracionCliente: values.aclaracionFirma,
          horometro: values.horometro,
          hodometro: values.hodometro,
          flagCrm: "0",
          idInterno: values.idInterno,
          repuestosPorServicios: handleSubmit(),
          imagenesPorServicios: imgServicio,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "iron-token": userToken,
        },
      };

      if (navigator.onLine) {
        setLoading(true);
        const response = await fetch(UrlServicios, requestOptions);
        const data = await response.json();
        setLoading(false);
        swal({
          title: "Servicio Creado!",
          icon: "success",
          showConfirmButton: false,
          timer: 4000,
        });
        if(data.message ==="Servicio creado correctamente"){
          navigate("/Servicios");
        }
        
      } else {
        const confirmacionOfline = await swal({
          title: "Modo offline",
          text: "Los datos se guardaran localmente,cuando estes online envia los datos desde servicios pendientes de enviar.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        });

        if (!confirmacionOfline) {
          return; // No se realiza ninguna acción adicional si el usuario cancela
        }
        // Guardar los datos del post en el almacenamiento local
        const offlinePosts =
          JSON.parse(localStorage.getItem("offlinePosts")) || [];

        offlinePosts.push(requestOptions);
        localStorage.setItem("offlinePosts", JSON.stringify(offlinePosts));

        // Registrar la sincronización en el Service Worker
        if ("serviceWorker" in navigator && "SyncManager" in window) {
          navigator.serviceWorker.ready
            .then(function (registration) {
              return registration.sync.register("syncPosts");
            })
            .catch(function (error) {
              console.log("Error al registrar la sincronización:", error);
            });
        }

        swal({
          title: "Servicio Guardado Localmente!",
          icon: "success",
          showConfirmButton: false,
          timer: 4000,
        });

        navigate("/Servicios-Pendientes");
      }
    } catch (err) {
      swal({
        title: "Ocurrió un error, contactar con el administrador",
        icon: "error",
        showConfirmButton: false,
        timer: 4000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event, lineId, fieldId) => {
    const updatedLines = lines.map((line) => {
      if (line.id === lineId) {
        return {
          ...line,
          [fieldId]: event.target.value,
        };
      }
      return line;
    });
    setLines(updatedLines);
  };

  const handleSubmit = () => {
    const formData = [];
    lines.forEach((line) => {
      const data = {};
      Object.keys(line).forEach((key) => {
        if (key !== "id") {
          data[key] = line[key];
        }
      });
      formData.push(data);
    });
    return formData;
  };
  const addLine = () => {
    const newLine = { id: lines.length + 1 };
    setLines([...lines, newLine]);
  };

  const deleteLine = () => {
    if (lines.length > 1) {
      const updatedLines = [...lines];
      updatedLines.pop();
      setLines(updatedLines);
    }
  };

  const handleChangeFlagReparacion = (event) => {
    const { checked } = event.target;
    formik.setFieldValue("flagReparacion", checked);
  };

  const handleChangeFlagServicio = (event) => {
    const { checked } = event.target;
    formik.setFieldValue("flagServicio", checked);
  };

  const handleChangeFlagGarantia = (event) => {
    const { checked } = event.target;
    formik.setFieldValue("flagGarantia", checked);
  };
  
  return (
    <>
      <Box sx={styleBox}>
        <Typography
          variant="h6"
          color="white"
          align="center"
          pb={1}
          marginBottom={"20px"}
          style={{
            backgroundColor: "#161616",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          Solicitud de servicio técnico
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="cliente"
                label="Cliente"
                variant="outlined"
                fullWidth
                required
                value={formik.values.cliente}
                onChange={formik.handleChange}
                error={formik.touched.cliente && Boolean(formik.errors.cliente)}
                helperText={formik.touched.cliente && formik.errors.cliente}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField
                  label="Fecha"
                  value={fecha}
                  onChange={(newValue) => setFecha(newValue)}
                  fullWidth
                  required
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="contacto"
                label="contacto"
                variant="outlined"
                fullWidth
                value={formik.values.contacto}
                onChange={formik.handleChange}
                error={
                  formik.touched.contacto && Boolean(formik.errors.contacto)
                }
                helperText={formik.touched.contacto && formik.errors.contacto}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="direccion"
                label="Dirección"
                variant="outlined"
                fullWidth
                value={formik.values.direccion}
                onChange={formik.handleChange}
                error={
                  formik.touched.direccion && Boolean(formik.errors.direccion)
                }
                helperText={formik.touched.direccion && formik.errors.direccion}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="localidad"
                label="Localidad"
                variant="outlined"
                fullWidth
                value={formik.values.localidad}
                onChange={formik.handleChange}
                error={
                  formik.touched.localidad && Boolean(formik.errors.localidad)
                }
                helperText={formik.touched.localidad && formik.errors.localidad}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="provincia"
                label="Provincia"
                variant="outlined"
                fullWidth
                value={formik.values.provincia}
                onChange={formik.handleChange}
                error={
                  formik.touched.provincia && Boolean(formik.errors.provincia)
                }
                helperText={formik.touched.provincia && formik.errors.provincia}
                required
              />
            </Grid>
               {/*
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="telefono"
                    label="Telefono"
                    variant="outlined"
                    fullWidth
                    value={formik.values.telefono}
                    onChange={formik.handleChange}
                    error={formik.touched.telefono && Boolean(formik.errors.telefono)}
                    helperText={formik.touched.telefono && formik.errors.telefono}

                  />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="mail"
                    label="Mail"
                    variant="outlined"
                    fullWidth
                    value={formik.values.mail}
                    onChange={formik.handleChange}
                    error={formik.touched.mail && Boolean(formik.errors.mail)}
                    helperText={formik.touched.mail && formik.errors.mail}

                  />
                  </Grid>
                */}

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="modelo"
                label="Modelo"
                variant="outlined"
                fullWidth
                value={formik.values.modelo}
                onChange={formik.handleChange}
                error={formik.touched.modelo && Boolean(formik.errors.modelo)}
                helperText={formik.touched.modelo && formik.errors.modelo}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="chasis"
                label="N° Chásis"
                variant="outlined"
                fullWidth
                value={formik.values.chasis}
                onChange={formik.handleChange}
                error={formik.touched.chasis && Boolean(formik.errors.chasis)}
                helperText={formik.touched.chasis && formik.errors.chasis}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="motor"
                label="N° Motor"
                variant="outlined"
                fullWidth
                value={formik.values.motor}
                onChange={formik.handleChange}
                error={formik.touched.motor && Boolean(formik.errors.motor)}
                helperText={formik.touched.motor && formik.errors.motor}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="horometro"
                label="Horometro"
                variant="outlined"
                fullWidth
                value={formik.values.horometro}
                onChange={formik.handleChange}
                error={
                  formik.touched.horometro && Boolean(formik.errors.horometro)
                }
                helperText={formik.touched.horometro && formik.errors.horometro}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="hodometro"
                label="Hodometro"
                variant="outlined"
                fullWidth
                value={formik.values.hodometro}
                onChange={formik.handleChange}
                error={
                  formik.touched.hodometro && Boolean(formik.errors.hodometro)
                }
                helperText={formik.touched.hodometro && formik.errors.hodometro}
              />
            </Grid>
          </Grid>
        </Box>

        <Box alignItems="center" marginTop="20px">
          <FormLabel sx={{ marginLeft: "15px" }}>Tipo de servicio</FormLabel>
          <FormControlLabel
            sx={{ marginLeft: "15px" }}
            control={
              <Checkbox
                checked={formik.values.flagReparacion}
                onChange={handleChangeFlagReparacion}
                disabled={
                  formik.values.otros !== "" ||
                  formik.values.flagServicio ||
                  formik.values.flagGarantia
                }
                error={
                  formik.touched.flagReparacion &&
                  Boolean(formik.errors.flagReparacion)
                }
                helperText={
                  formik.touched.flagReparacion && formik.errors.flagReparacion
                }
                required
              />
            }
            label="Reparacion"
          />
          <FormControlLabel
            sx={{ marginLeft: "15px" }}
            control={
              <Checkbox
                checked={formik.values.flagServicio}
                onChange={handleChangeFlagServicio}
                disabled={
                  formik.values.otros !== "" ||
                  formik.values.flagReparacion ||
                  formik.values.flagGarantia
                }
                required
              />
            }
            label="Servicio"
          />
          <FormControlLabel
            sx={{ marginLeft: "15px" }}
            control={
              <Checkbox
                checked={formik.values.flagGarantia}
                onChange={handleChangeFlagGarantia}
                disabled={
                  formik.values.otros !== "" ||
                  formik.values.flagServicio ||
                  formik.values.flagReparacion
                }
                required
              />
            }
            label="Garantia"
          />
          <TextField
            id="otros"
            label="Otros"
            variant="outlined"
            fullWidth
            value={formik.values.otros}
            onChange={formik.handleChange}
            disabled={
              formik.values.flagReparacion ||
              formik.values.flagServicio ||
              formik.values.flagGarantia
            }
            error={formik.touched.otros && Boolean(formik.errors.otros)}
            helperText={formik.touched.otros && formik.errors.otros}
            required
          />
        </Box>
      </Box>
      <Box sx={styleBox}>
        <Typography
          variant="h6"
          color="white"
          align="center"
          pb={1}
          marginBottom={"20px"}
          style={{
            backgroundColor: "#ED9519",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          Descripción de la falla y causa
        </Typography>
        <TextField
          id="falla"
          label="Descripción de la falla y causa"
          multiline
          fullWidth
          minRows={3}
          maxRows={10}
          value={formik.values.falla}
          onChange={formik.handleChange}
          error={formik.touched.falla && Boolean(formik.errors.falla)}
          helperText={formik.touched.falla && formik.errors.falla}
          required
        />
        {/*navigator.onLine && ( */
          <Box>
            <FileUploader
              setImgServicio={setImgServicio}
              imgServicio={imgServicio}
              setLoadFile={setLoadFile}
              idInterno= {formik.values.idInterno}
            ></FileUploader>
          </Box>
        //)
        }

        <Typography
          variant="h6"
          color="white"
          align="center"
          pb={1}
          marginTop={"40px"}
          marginBottom={"20px"}
          style={{
            backgroundColor: "#161616",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          Servicio realizado
        </Typography>
        <TextField
          id="servicioRealizado"
          label="Servicio realizado"
          multiline
          fullWidth
          minRows={3}
          maxRows={10}
          marginBottom={"60px"}
          value={formik.values.servicioRealizado}
          onChange={formik.handleChange}
          error={
            formik.touched.servicioRealizado &&
            Boolean(formik.errors.servicioRealizado)
          }
          helperText={
            formik.touched.servicioRealizado && formik.errors.servicioRealizado
          }
          required
        />
      </Box>
   

      <Box sx={styleBox}>
        <Typography
          variant="h6"
          color="white"
          align="center"
          pb={1}
          marginBottom={"20px"}
          style={{
            backgroundColor: "#161616",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          Datos del tecnico
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="TecnicoInterviniente"
                label="Tecnico Interviniente"
                variant="outlined"
                fullWidth
                value={tecnico}
                onChange={(e) => setTecnico(e.target.value)}
                InputProps={{
                  readOnly: true,
                }}
                disabled
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="km"
                label="Km. Recorrido"
                variant="outlined"
                type="number"
                fullWidth
                value={formik.values.km}
                onChange={formik.handleChange}
                error={formik.touched.km && Boolean(formik.errors.km)}
                helperText={formik.touched.km && formik.errors.km}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Hora inicio de la tarea"
                  value={horaInicio}
                  onChange={(newValue) => setHoraInicio(newValue)}
                  required
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Hora fin de la tarea"
                  value={horaFin}
                  onChange={(newValue) => setHoraFin(newValue)}
                  required
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={styleBox}>
        <Typography
          variant="h6"
          color="white"
          align="center"
          pb={1}
          marginBottom={"20px"}
          style={{
            backgroundColor: "#ED9519",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          Manifiesta conforme con la reparacion efectuada
        </Typography>
        <FormLabel
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
            marginTop: "30PX",
          }}
        >
          Firma del cliente:{" "}
        </FormLabel>

        <Box className="box-signature" marginBottom={"20px"}>
          <SignatureCanvas
            ref={padRef}
            canvasProps={{
              width: 300,
              height: 200,
            }}
          />

          <hr />

          {canvasVisibility && <img src={canvas} alt="signature" />}

          <Button onClick={clearSignatureCanvas}>Limpiar</Button>
          <Button onClick={handleGetCanvas}>Guardar</Button>
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
          marginBottom={"20px"}
        >
          <Grid item xs={6}>
            <TextField
              id="dni"
              label="DNI cliente"
              variant="outlined"
              marginTop="20px"
              marginBottom ="20px"
              fullWidth
              value={formik.values.dni}
              onChange={formik.handleChange}
              error={formik.touched.dni && Boolean(formik.errors.dni)}
              helperText={formik.touched.dni && formik.errors.dni}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="aclaracionFirma"
              label="Aclaracion firma"
              variant="outlined"
              marginTop="20px"
              fullWidth
              value={formik.values.aclaracionFirma}
              onChange={formik.handleChange}
              error={
                formik.touched.aclaracionFirma &&
                Boolean(formik.errors.aclaracionFirma)
              }
              helperText={
                formik.touched.aclaracionFirma && formik.errors.aclaracionFirma
              }
              required
            />
          </Grid>
        </Grid>
      </Box>
      {loading && <Loading />}
      <Box sx={styleBox}>
        <Button
          variant="contained"
          fullWidth
          color="success"
          endIcon={<SendIcon />}
          style={{
            //backgroundColor: "#161616",
            //color: "#FFFFFF",
            marginTop: "20px",
          }}
          type="submit"
          onClick={() => {
            preValidation();
          }}
        >
          Enviar formulario
        </Button>
      </Box>
    </>
  );
}
