export const colums =[
    {
      accessorKey: "id",
      header: "ID",
    },
    {
      accessorKey: "flagCrm",
      header: "Enviado CRM",
    },

    {
      accessorKey: "cliente",
      header: "Cliente",
    },
    {
      accessorKey: "fecha",
      header: "Fecha",
    },
    {
      accessorKey: "contacto",
      header: "Contacto",
    },
    {
      accessorKey: "direccion",
      header: "Dirección",
    },
    {
      accessorKey: "localidad",
      header: "Localidad",
    },
    {
      accessorKey: "tecnico",
      header: "Técnico",
    },
   
  ]

  export const columsOrdenes =[
    {
      accessorKey: "id",
      header: "ID",
    },
    {
      accessorKey: "ordenTrabajo",
      header: "ot",
    },
 
    {
      accessorKey: "cliente",
      header: "Cliente",
    },
    {
      accessorKey: "fecha",
      header: "Fecha",
    },
    {
      accessorKey: "modelo",
      header: "modelo",
    },
    {
      accessorKey: "estado",
      header: "Estado",
    },
    {
      accessorKey: "serie",
      header: "Serie",
    },
   
  ]

  export const columsReportes =[
    {
      accessorKey: "id",
      header: "ID",
    },
    {
      accessorKey: "tipo",
      header: "Tipo",
    },
 
    {
      accessorKey: "cliente",
      header: "Cliente",
    },
    {
      accessorKey: "fecha",
      header: "Fecha",
    },
    {
      accessorKey: "modelo",
      header: "modelo",
    },
    {
      accessorKey: "ubicacion",
      header: "Ubicacion",
    },
    {
      accessorKey: "serie",
      header: "Serie",
    },
   
  ]