import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  Dialog,
} from "@mui/material";
import api from "../components/utils/api";
import { MaterialReactTable } from "material-react-table";
import IconButton from "@mui/material/IconButton";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { columsOrdenes } from "../components/utils/Columns";
import { Delete } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import swal from "sweetalert";
import Loading from "../components/Loading/Loading";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OrdenDeTrabajo from "./NuevaOrdenDeTrabajo";



export default function OrdebesDeTrabajo() {


  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const userToken = localStorage.getItem("user-token");
  const UrlServicios = api.ordenes 
  const [servicios, setServicios] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [mode, setMode] = useState("");
  const [editingDataservicios, setEditingDataservicios] = useState({});
  const [loading, setLoading] = useState(false);
  

  const rol = localStorage.getItem("roles");
  const mostrarBotones = rol === "ROLE_ADMIN";

  const ListarServicios = async () => {
    try {
      const headers = { "iron-token": userToken };
      setLoading(true);
      let res = await fetch(UrlServicios, { headers });
      setLoading(false);
      if (res.ok) {
        let data = await res.json();
        setServicios(data);
      } else {
        setLoading(false);
        throw new Error(
          "No fue posible obtener los registros, vuelve a intentar más tarde."
        );
      }
    } catch (error) {
      setLoading(false);
      swal("Error", error.message, "error");
    }
  };

  const finalizar = async (row) =>{

       // Mostrar una confirmación utilizando SweetAlert
       swal({
        title: "¿Estás seguro?",
        text: "Esta acción finalizara la orden permanentemente.",
        icon: "warning",
        buttons: ["Cancelar", "Finalizar"],
        dangerMode: true,
      }).then(async (confirm) => {
        if (confirm) {
          const requestOptions = {
            method: "PUT",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              "iron-token": userToken,
            },
          };
  
          try {
            setLoading(true);
            const response = await fetch(`${UrlServicios}estado/${row.id}`, requestOptions);
            const data = await response.json();
            setLoading(false);
            if (response.ok) {
              swal(
                "Actualizado!",
                "El registro se finalizo correctamente.",
                "success"
              );
              ListarServicios();
            } else {
              setLoading(false);
              swal("Error", "Ocurrió un error al finalizar el registro.", "error");
            }
          } catch (error) {
            setLoading(false);
            swal("Error", "Ocurrió un error al finalizar el registro.", "error");
          }
        }
      });
  }
  // elimina registro

  const eliminarRegistro = async (id, row) => {
    // Mostrar una confirmación utilizando SweetAlert
    swal({
      title: "¿Estás seguro?",
      text: "Esta acción eliminará el registro permanentemente.",
      icon: "warning",
      buttons: ["Cancelar", "Eliminar"],
      dangerMode: true,
    }).then(async (confirm) => {
      if (confirm) {
        if (row.flagCrm === "1") {
          swal("Error", "No se pueden eliminar registros informados al Crm.", "error");
          return
        }
        const requestOptions = {
          method: "DELETE",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "iron-token": userToken,
          },
        };

        try {
          setLoading(true);
          const response = await fetch(`${UrlServicios}${id}`, requestOptions);
          const data = await response.json();
          setLoading(false);
          if (response.ok) {
            swal(
              "¡Eliminado!",
              "El registro se eliminó correctamente.",
              "success"
            );
            ListarServicios();
          } else {
            setLoading(false);
            swal("Error", "Ocurrió un error al eliminar el registro.", "error");
          }
        } catch (error) {
          setLoading(false);
          swal("Error", "Ocurrió un error al eliminar el registro.", "error");
        }
      }
    });
  };

  const accion = (id, row, edit) => {
    if (edit) {
      setEditingData(row);
      setMode("edit");
    } else {
      setMode("view");
    }
    setIsModalOpen(true);
    setEditingDataservicios(row.original);
  };




  const columsOrdene = useMemo(() => columsOrdenes, []);

  useEffect(() => {
    ListarServicios();
  }, []);

  return (
    <>
      <Box
        id="ok"
        sx={{
          "& .MuiTextField-root": { m: 1 },
          boxShadow: 5,
          p: 4,
          m: 2,
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          color="white"
          align="center"
          pb={1}
          marginBottom={"20px"}
          style={{
            backgroundColor: "#161616",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          Ordenes de trabajo
        </Typography>
        {loading && <Loading />}
        <MaterialReactTable
          columns={columsOrdene}
          data={servicios}
          enableRowActions
          renderRowActions={({ row }) => (
            <Box>
              <IconButton
                onClick={() => accion(row.original.id, row, false)}
              >
                <RemoveRedEyeIcon />
              </IconButton>
              {mostrarBotones && (
                <>
                 <IconButton onClick={() => finalizar(row.original)}>
                    <CheckCircleIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => accion(row.original.id, row, true)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => eliminarRegistro(row.original.id, row.original)}>
                    <Delete />
                  </IconButton>
                 
                </>
              )}
            </Box>
          )}
        />
      </Box>

      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        maxWidth="md"
        fullWidth
      >
        {/* Add your editing fields here using 'editingData' */}
        <OrdenDeTrabajo mode={mode} initialData={editingDataservicios}></OrdenDeTrabajo>
  
      </Dialog>
    </>
  );
}
