import React, { useState, useEffect } from 'react';
import { Box, Grid, TextField, Typography, Button, Autocomplete } from '@mui/material';
import SendIcon from "@mui/icons-material/Send";
import Cabecera from './ReporteServicios/Cabecera';
import Detalle from './ReporteServicios/Detalle';
import { maintenanceData } from '../components/utils/maintenanceData';
import { styleBox } from '../components/utils/Stylebox';
import api from '../components/utils/api';
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading/Loading";

const NuevoReporteServicio = ({ mode = 'create', initialData = {} }) => {


  const userToken = localStorage.getItem("user-token");
  const [tipoReporte, setTipoReporte] = useState(initialData.tipoReporte || null);
  const [reporteElegido, setReporteElegido] = useState(initialData.tipoReporte || null);
  const [selectedCondition, setSelectedCondition] = useState(initialData.tipo || '');
  const [selectedTransmision, setSelectedTransmision] = useState(initialData.tipoTransmision || '');
  const [cliente, setCliente] = useState(initialData.cliente || null);
  const [ubicacion, setUbicacion] = useState(initialData.ubicacion || null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [id, setId] = useState(initialData.id || null);
  const user = localStorage.getItem("nombre-Completo");

  const [condiciones, setCondiciones] = useState({
    serieMotor1: initialData.serieMotor || '',
    modeloMotor1: initialData.modeloMotor || '',
    modelo: initialData.modelo || '',
    chasis: initialData.chasis || '',
    serie: initialData.serie || '',
    modeloMotor: initialData.modeloMotor || '',
    serieMotor: initialData.serieMotor || '',
    fecha: initialData.fecha || new Date().toISOString().split('T')[0],
    horometro: initialData.horometro || ''
  });

  const [condicionesServicio, setCondicionesServicios] = useState(initialData.condicionesPorServicios || maintenanceData)

  const [detalleState, setDetalleState] = useState({
    data: initialData.condicionesPorServicios || maintenanceData,
    firmaI: initialData.firmaI || user,
    aclaracionI: initialData.aclaracionInspector || user,
    firmaC: initialData.firmaCliente || '',
    aclaracionC: initialData.aclaracionCliente || '',
    comnetarioI: initialData.comentarioInspector || '',
    comnetarioC: initialData.comentarioCliente || ''
  });
  const reportes = [
    { label: "MOTONIVELADORA", value: 1 },
    { label: "MINICARGADOR", value: 2 },
    { label: "PALACARGADORA", value: 3 },
    { label: "PALA-RETRO", value: 4 },
    { label: "EXCAVADORA HIDRAULICA", value: 5 },
    { label: "HIDROGRUA", value: 6 },
    { label: "TRACTOR", value: 7 },
    { label: "RODILLO", value: 8 },
    { label: "CAMION FUERA DE RUTA", value: 9 },
    { label: "AUTOELEVADORES", value: 10 },
    { label: "GENERADOR", value: 11 },
    { label: "GRUA", value: 12 },
    { label: "TOPADOR", value: 13 },
    { label: "BOMBA DE HORMIGON", value: 14 },
  ];

  const UrlServicios = api.reporteServicio;

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setCondiciones({
      ...condiciones,
      [id]: value
    });
  };

  const handleSelectChange = (event) => {
    setSelectedTransmision(event.target.value);
  };

  const handleDetalleChange = (field, value) => {
    setDetalleState({
      ...detalleState,
      [field]: value
    });
  };

  const handleAutoCompleteChange = (event, newValue) => {
    setTipoReporte(newValue.label);
    setReporteElegido(newValue.label);
  };

   const handleConditionChange = (id, newCondition) => {
    const newData = [...detalleState.data];
    const itemIndex = newData.findIndex(item => item.id === id);
    if (itemIndex !== -1) {
      newData[itemIndex].condicion = newCondition;
      newData[itemIndex].updatedAt = new Date().toISOString();
      setDetalleState({ ...detalleState, data: newData });
    }
 
  };

  const validarCampos = () => {
    const camposRequeridos = {
      tipoReporte,
      cliente,
      ubicacion,
      selectedCondition,
      selectedTransmision,
      usuario: user,
      reporteElegido,
      comentarioInspector: detalleState.comnetarioI,
      comentarioCliente: detalleState.comnetarioC,
      aclaracionInspector: detalleState.aclaracionI,
      aclaracionCliente: detalleState.aclaracionC,
      firmaCliente: detalleState.firmaC,
      ...condiciones,
      ...detalleState
    };
  
    for (let [campo, valor] of Object.entries(camposRequeridos)) {
      if (!valor) {
        swal({
          title: "Error",
          text: `El campo ${campo} es obligatorio`,
          icon: "error",
          button: "Aceptar"
        });
        return false;
      }
    }
    return true;
  };

  const enviarFormulario = async () => {
    if (!validarCampos()) {
      return;
    }
  
    try {
      const confirmacion = await swal({
        title: "¿Estás seguro? Se generará la orden de trabajo, por favor verifica los datos!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
  
      if (!confirmacion) {
        return;
      }
      const bodyReporte = {
        ...condiciones,
        ...detalleState,
        tipo: selectedCondition,
        tipoTransmision: selectedTransmision,
        cliente: cliente,
        ubicacion: ubicacion,
        usuario: user,
        tipoReporte,
        reporteElegido,
        comentarioInspector: detalleState.comnetarioI,
        comentarioCliente: detalleState.comnetarioC,
        aclaracionInspector: detalleState.aclaracionI,
        aclaracionCliente: detalleState.aclaracionC,
        firmaCliente: detalleState.firmaC,
      };
      console.log(bodyReporte);
      const url = mode === "edit" ? `${UrlServicios}${id}` : UrlServicios;
      const requestOptions = {
        method: mode === "edit" ? "PUT" : "POST",
        body: JSON.stringify(bodyReporte),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "iron-token": userToken,
        },
      };
  
      setLoading(true);
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      setLoading(false);
  
      if (response.status === 201 || response.status === 200) {
        swal({
          title: mode === "edit" ? "Reporte de servicio actualizado!" : "Reporte de servicio creado!",
          icon: "success",
          showConfirmButton: false,
          timer: 4000,
        });
        navigate("/ReporteServicio");
      } else if (response.status === 400) {
        swal({
          title: "Error en los datos enviados",
          text: data.message,
          icon: "error",
          showConfirmButton: true,
        });
      } else if (response.status === 500) {
        swal({
          title: "Error del servidor",
          text: "Ocurrió un error en el servidor, por favor intenta nuevamente más tarde.",
          icon: "error",
          showConfirmButton: true,
        });
      } else {
        swal({
          title: "Error desconocido",
          text: "Ocurrió un error inesperado, por favor intenta nuevamente.",
          icon: "error",
          showConfirmButton: true,
        });
      }
    } catch (err) {
      swal({
        title: "Ocurrió un error, contactar con el administrador",
        icon: "error",
        showConfirmButton: false,
        timer: 4000,
      });
    } finally {
      setLoading(false);
    }
  };
  if (loading){
    return <Loading />
  }

  return (
    <Box sx={styleBox}>
      <Typography
        variant="h6"
        color="white"
        align="center"
        pb={1}
        marginBottom={"20px"}
        style={{
          backgroundColor: "#161616",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        Reporte de servicio - {reporteElegido}
      </Typography>
    
      <Box>
        <Grid container spacing={2} sx={{ mt: 2, mb: 3 }} justifyContent="center">
          <Grid item xs={12} sm={4} md={4}>
            <Autocomplete
              value={tipoReporte}
              onChange={handleAutoCompleteChange}
              id="tiporeportes"
              options={reportes}
              renderInput={(params) => <TextField {...params} label="Tipo de Reporte" />}
              disabled={mode === 'view'}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              id="cliente"
              label="Cliente"
              variant="outlined"
              fullWidth
              required
              onChange={(e)=>{
                setCliente(e.target.value)
              }}
              value={cliente}
              disabled={mode === 'view'}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              id="ubicacion"
              label="Ubicación equipo"
              variant="outlined"
              fullWidth
              required
              onChange={(e)=>{
                setUbicacion(e.target.value)
              }}
              value={ubicacion}
              disabled={mode === 'view'}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        {tipoReporte && (
          <>
            <Cabecera
              mode={mode}
              condiciones={condiciones}
              handleInputChange={handleInputChange}
              selectedCondition={selectedTransmision}
              handleSelectChange={handleSelectChange}
            />
            <Detalle
              mode={mode}
              data={detalleState.data}
              condiciones = {condicionesServicio}
              handleConditionChange={handleConditionChange}
              selectedCondition={selectedCondition}
              setSelectedCondition={setSelectedCondition}
              firmaI={detalleState.firmaI}
              setFirmaI={(value) => handleDetalleChange('firmaI', value)}
              aclaracionI={detalleState.aclaracionI}
              setAclaracionI={(value) => handleDetalleChange('aclaracionI', value)}
              firmaC={detalleState.firmaC}
              setFirmaC={(value) => handleDetalleChange('firmaC', value)}
              aclaracionC={detalleState.aclaracionC}
              setAclaracionC={(value) => handleDetalleChange('aclaracionC', value)}
              comnetarioI={detalleState.comnetarioI}
              setComnetarioI={(value) => handleDetalleChange('comnetarioI', value)}
              comnetarioC={detalleState.comnetarioC}
              setComnetarioC={(value) => handleDetalleChange('comnetarioC', value)}
            />
          </>
        )}
      </Box>
      {mode !== 'view' && (
        <Button
          variant="contained"
          fullWidth
          color="success"
          endIcon={<SendIcon />}
          style={{ marginTop: "20px" }}
          onClick={enviarFormulario}
        >
          Enviar formulario
        </Button>
      )}
    </Box>
  );
};

export default NuevoReporteServicio;