import {
  Typography,
  Button,
  Box,
  TextField,
  Grid,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import "./stylefirma.css";
import api from "../components/utils/api";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import SendIcon from "@mui/icons-material/Send";
import swal from "sweetalert";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useNavigate } from "react-router-dom";
import "dayjs/locale/es";
import Loading from "../components/Loading/Loading";
import { styleBox } from "../components/utils/Stylebox";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("es");

export default function OrdenDeTrabajo({ mode, initialData }) {
  const userToken = localStorage.getItem("user-token");
  const urlOrdenes = api.ordenes;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [fecha, setFecha] = useState(dayjs().tz(dayjs.tz.guess()));
  const [cliente, setCliente] = useState("");
  const [modelo, setModelo] = useState("");
  const [chasis, setChasis] = useState("");
  const [serie, setSerie] = useState("");
  const [ot, setOt] = useState("");
  const [errors, setErrors] = useState({});
  const [id, setId] = useState("");

  useEffect(() => {
    if (mode === "edit" || mode === "view") {
     
      setFecha(dayjs(initialData.fecha).tz(dayjs.tz.guess()));
      setCliente(initialData.cliente);
      setModelo(initialData.modelo);
      setChasis(initialData.chasis);
      setSerie(initialData.serie);
      setOt(initialData.ordenTrabajo);
      setId(initialData.id)
    }
  }, [mode, initialData]);

  const validate = () => {
    let tempErrors = {};
    tempErrors.ot = ot ? "" : "Este campo es requerido.";
    tempErrors.cliente = cliente ? "" : "Este campo es requerido.";
    tempErrors.fecha = fecha ? "" : "Este campo es requerido.";
    tempErrors.chasis = chasis ? "" : "Este campo es requerido.";
    tempErrors.serie = serie ? "" : "Este campo es requerido.";
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  };
  

  const enviarFormulario = async () => {
    if (!validate()) {
      return;
    }

    try {
      const confirmacion = await swal({
        title: "¿Estás seguro? Se generará la orden de trabajo, por favor verifica los datos!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (!confirmacion) {
        return;
      }
      const url = mode === "edit" ? `${urlOrdenes}${id}`: urlOrdenes
      const requestOptions = {
        method: mode === "edit" ? "PUT" : "POST",
        body: JSON.stringify({
          ordenTrabajo: ot,
          cliente: cliente,
          fecha: fecha,
          modelo: modelo,
          chasis: chasis,
          serie: serie,
          estado: "Nuevo"
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "iron-token": userToken,
        },
      };

      setLoading(true);
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      setLoading(false);

      if (response.status === 201 || response.status === 200) {
        swal({
          title: mode === "edit" ? "Orden actualizada!" : "Orden creada!",
          icon: "success",
          showConfirmButton: false,
          timer: 4000,
        });
        navigate("/ordenes");
      } else if (response.status === 400) {
        swal({
          title: "Error en los datos enviados",
          text: data.message,
          icon: "error",
          showConfirmButton: true,
        });
      } else if (response.status === 500) {
        swal({
          title: "Error del servidor",
          text: "Ocurrió un error en el servidor, por favor intenta nuevamente más tarde.",
          icon: "error",
          showConfirmButton: true,
        });
      } else {
        swal({
          title: "Error desconocido",
          text: "Ocurrió un error inesperado, por favor intenta nuevamente.",
          icon: "error",
          showConfirmButton: true,
        });
      }
    } catch (err) {
      swal({
        title: "Ocurrió un error, contactar con el administrador",
        icon: "error",
        showConfirmButton: false,
        timer: 4000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={styleBox}>
        <Typography
          variant="h6"
          color="white"
          align="center"
          pb={1}
          marginBottom={"20px"}
          style={{
            backgroundColor: "#161616",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          {mode === "edit" ? "Editar orden de trabajo" : mode === "view" ? "Ver orden de trabajo" : "Crear nueva orden de trabajo"}
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="ot"
                label="OT"
                variant="outlined"
                fullWidth
                required
                value={ot}
                onChange={(e) => setOt(e.target.value)}
                error={!!errors.ot}
                helperText={errors.ot}
                disabled={mode === "view"}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="cliente"
                label="Cliente"
                variant="outlined"
                fullWidth
                required
                value={cliente}
                onChange={(e) => setCliente(e.target.value)}
                error={!!errors.cliente}
                helperText={errors.cliente}
                disabled={mode === "view"}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField
                  label="Fecha"
                  value={fecha}
                  onChange={(newValue) => setFecha(newValue)}
                  fullWidth
                  required
                  error={!!errors.fecha}
                  helperText={errors.fecha}
                  disabled={mode === "view"}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="modelo"
                label="Modelo"
                variant="outlined"
                fullWidth
                value={modelo}
                onChange={(e) => setModelo(e.target.value)}
                disabled={mode === "view"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="chasis"
                label="N° Chásis"
                variant="outlined"
                fullWidth
                required
                value={chasis}
                onChange={(e) => setChasis(e.target.value)}
                error={!!errors.chasis}
                helperText={errors.chasis}
                disabled={mode === "view"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="serie"
                label="N° Serie"
                variant="outlined"
                fullWidth
                required
                value={serie}
                onChange={(e) => setSerie(e.target.value)}
                error={!!errors.serie}
                helperText={errors.serie}
                disabled={mode === "view"}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      {loading && <Loading />}
      {mode !== "view" && (
        <Box sx={styleBox}>
          <Button
            variant="contained"
            fullWidth
            color="success"
            endIcon={<SendIcon />}
            style={{
              marginTop: "20px",
            }}
            type="submit"
            onClick={enviarFormulario}
          >
            {mode === "edit" ? "Actualizar orden de trabajo" : "Guardar orden de trabajo"}
          </Button>
        </Box>
      )}
    </>
  );
}