import Navbar from "./components/menu/Navbar";

import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PostAddIcon from "@mui/icons-material/PostAdd";

import Stack from '@mui/material/Stack';
import AlertDialogSlide from "./components/Dialog/AlertDialogSlide";
//import NoteAltIcon from '@mui/icons-material/NoteAlt';
//import DesignServicesIcon from '@mui/icons-material/DesignServices';
//import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
//import DomainAddOutlinedIcon from '@mui/icons-material/DomainAddOutlined';
//import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';

const navegationLinks = [
  {
    title: "Home",
    path: "/",
    icon: <HomeIcon />,
  },
  {
    title: "Nuevo Servicio",
    path: "NuevoServicio",
    icon: <PostAddIcon />,
  },
  {
    title: "Servicios",
    path: "Servicios",
    icon: <AssignmentOutlinedIcon />,
  },

  {
    title: "Cargar horas",
    path: "cargar-horas",
    icon: <QueryBuilderIcon />,
  },
  {
    title: "Reporte Servicios",
    path: "ReporteServicio",
    icon: <DashboardIcon />,
  },
  {
    title: "Nuevo Reporte Servicios",
    path: "NuevoReporteServicio",
    icon: <DashboardCustomizeIcon />,
  },
  {
    title: "Servicios pendientes de enviar",
    path: "Servicios-Pendientes",
    icon: <PendingActionsIcon />,
  },
  /*
    {
    title: "Nueva Orden de trabajo",
    path: "CrearOt",
    icon: <DomainAddOutlinedIcon />,
  },
  {
    title: "Ordenes de trabajo",
    path: "ordenes",
    icon: <DomainOutlinedIcon />,
  },
  {
    title: "Servicios pendientes de firmar",
    path: "Servicios-Pendientes",
    icon: <NoteAltIcon />,
  },
  */


];

function App() {
  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = () => {
    const userToken = localStorage.getItem("user-token");

    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
      return navigate("/auth/login");
    } else {
      setIsLoggedIn(true);
    }
  };

  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);


  const [waitingWorker, setWaitingWorker] = useState(null);

  const onUpdate = (registration) => {
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    navigator.serviceWorker.register('/service-worker.js').then((registration) => {
      if (registration.waiting) {
        onUpdate(registration);
      }

      registration.addEventListener('updatefound', () => {
        onUpdate(registration);
      });
      if (waitingWorker) {
        waitingWorker.postMessage({ type: 'SKIP_WAITING' })
        window.location.reload();
      }
    });
  }, []);

  const updateServiceWorker = () => {
    waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    window.location.reload();
  };

  return (
    <>
      <React.Fragment>
        {waitingWorker && (
          <Stack sx={{ width: '100%' }} spacing={2}>
            <AlertDialogSlide updateServiceWorker={() => { updateServiceWorker() }}></AlertDialogSlide>
          </Stack>
        )}
        {isLoggedIn && (

          <Navbar
            navegationLinks={navegationLinks}
            children={<Outlet />}
          ></Navbar>

        )}
      </React.Fragment>
    </>
  );
}

export default App;
