import React, { useRef, useState, useCallback } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Select, MenuItem, Box, Grid, TextField, FormLabel, Button
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AdjustIcon from '@mui/icons-material/Adjust';
import SignatureCanvas from "react-signature-canvas";
import { styleBox } from "../../components/utils/Stylebox";

const Detalle = ({ mode, data, handleConditionChange, selectedCondition, setSelectedCondition, firmaI, setFirmaI, aclaracionI, setAclaracionI, firmaC, setFirmaC, aclaracionC, setAclaracionC, comnetarioI, setComnetarioI, comnetarioC, setComnetarioC, condiciones }) => {

  const padRef = useRef(null);
  const [canvasVisibility, setCanvasVisibility] = useState(false);

  const clearSignatureCanvas = useCallback(() => {
    padRef?.current?.clear();
    setFirmaC("");
    setCanvasVisibility(false);
  }, [setFirmaC]);

  const handleGetCanvas = useCallback(() => {
    const canvas = padRef?.current?.toDataURL();
    setFirmaC(canvas);
    setCanvasVisibility(true);
  }, [setFirmaC]);

  // Agrupar condiciones por sección
  const groupedConditions = condiciones.reduce((acc, item) => {
    if (!acc[item.seccion]) {
      acc[item.seccion] = [];
    }
    acc[item.seccion].push(item);
    return acc;
  }, {});

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h6">Selecciona una condición:</Typography>
        <Select
          value={selectedCondition}
          onChange={(e) => setSelectedCondition(e.target.value)}
          displayEmpty
          fullWidth
          disabled={mode === 'view'}
        >
          <MenuItem value=""><em>None</em></MenuItem>
          <MenuItem value="P">P: Pre Entrega</MenuItem>
          <MenuItem value="E">E: Entrega</MenuItem>
          <MenuItem value="1">1: 250 km</MenuItem>
          <MenuItem value="2">2: 500 km</MenuItem>
          <MenuItem value="3">3: 1000 km</MenuItem>
          <MenuItem value="4">4: 1250 km</MenuItem>
          <MenuItem value="5">5: mas de 1250 km</MenuItem>
          <MenuItem value="U">U: Entrega Usado</MenuItem>
          <MenuItem value="X">X: Entrega Adicional</MenuItem>
        </Select>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ítems</TableCell>
              <TableCell align="center">{selectedCondition}</TableCell>
            </TableRow>
          </TableHead>
          {selectedCondition ? (
            <TableBody>
              {Object.keys(groupedConditions).map((seccion) => (
                <React.Fragment key={seccion}>
                  <TableRow>
                    <TableCell colSpan={2} style={{ backgroundColor: '#FF7F0E', color: 'white' }}>
                      <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        {seccion}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {groupedConditions[seccion].map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.descripcion}</TableCell>
                      <TableCell align="center">
                        <Select
                          value={item.condicion}
                          onChange={(e) => handleConditionChange(item.id, e.target.value)}
                          displayEmpty
                          fullWidth
                          disabled={mode === 'view'}
                        >
                          <MenuItem value="Buena"><CheckCircleIcon color="primary" /></MenuItem>
                          <MenuItem value="Mala"><CancelIcon color="error" /></MenuItem>
                          <MenuItem value="Efectuada"><AdjustIcon color="action" /></MenuItem>
                        </Select>
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          ) : ""}
        </Table>
      </TableContainer>

      <Box sx={{ padding: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">Comentarios Inspector:</Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Escribe comentarios aquí"
              multiline
              rows={2}
              disabled={mode === 'view'}
              value={comnetarioI}
              onChange={(e) => setComnetarioI(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Comentarios Cliente:</Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Escribe comentarios aquí"
              multiline
              rows={2}
              disabled={mode === 'view'}
              value={comnetarioC}
              onChange={(e) => setComnetarioC(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ marginY: 2 }}>
              El Equipo queda en servicio, en condiciones satisfactorias. La
              información adecuada se ha dado sobre su funcionamiento, cuidado,
              mantenimiento y operación. Se entrega al cliente los manuales de
              Partes, Operación y Mantenimiento. Los resultados de la inspección
              se han explicado.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={styleBox}>
              <Typography
                variant="h6"
                color="white"
                align="center"
                pb={1}
                marginBottom={"20px"}
                style={{
                  backgroundColor: "#ED9519",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                Manifiesta conforme con la inspeccion efectuada
              </Typography>
              <FormLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                  marginTop: "30PX",
                }}
              >
                Firma del cliente:{" "}
              </FormLabel>
              <Box className="box-signature" marginBottom={"20px"}>
                <SignatureCanvas
                  ref={padRef}
                  canvasProps={{
                    width: 300,
                    height: 200,
                  }}
                />
                <hr />
                {canvasVisibility && <img src={firmaC} alt="signature" />}
                <Button onClick={clearSignatureCanvas}>Limpiar</Button>
                <Button onClick={handleGetCanvas}>Guardar</Button>
              </Box>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                marginBottom={"20px"}
              >
                <Grid item xs={6}>
                  <TextField
                    id="dni"
                    label="Inspector"
                    variant="outlined"
                    marginTop="20px"
                    marginBottom="20px"
                    fullWidth
                    value={firmaI}
                    onChange={(e) => setFirmaI(e.target.value)}
                    disabled={true}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="aclaracionFirma"
                    label="Aclaracion firma"
                    variant="outlined"
                    marginTop="20px"
                    fullWidth
                    value={aclaracionC}
                    onChange={(e) => setAclaracionC(e.target.value)}
                    disabled={mode === 'view'}
                    required
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Detalle;