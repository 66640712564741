 export const maintenanceData = [
  {
      "id": 1,
      "ReporteDeServicioId": 1,
      "seccion": "MOTOR",
      "descripcion": "Cambio Aceite de Motor",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:16.000Z",
      "updatedAt": "2024-12-20T00:49:16.000Z"
  },
  {
      "id": 2,
      "ReporteDeServicioId": 1,
      "seccion": "MOTOR",
      "descripcion": "Cambio Filtros de Aceite Motor",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:16.000Z",
      "updatedAt": "2024-12-20T00:49:16.000Z"
  },
  {
      "id": 3,
      "ReporteDeServicioId": 1,
      "seccion": "MOTOR",
      "descripcion": "Cambio filtros de combustible (Principal, secuandario, sedimantación)",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:17.000Z",
      "updatedAt": "2024-12-20T00:49:17.000Z"
  },
  {
      "id": 4,
      "ReporteDeServicioId": 1,
      "seccion": "MOTOR",
      "descripcion": "Comprobar el estado del turbo cargador (modelos aplicables)",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:18.000Z",
      "updatedAt": "2024-12-20T00:49:18.000Z"
  },
  {
      "id": 5,
      "ReporteDeServicioId": 1,
      "seccion": "MOTOR",
      "descripcion": "Comprobar nivel de aceite en motor",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:18.000Z",
      "updatedAt": "2024-12-20T00:49:18.000Z"
  },
  {
      "id": 6,
      "ReporteDeServicioId": 1,
      "seccion": "MOTOR",
      "descripcion": "Comprobar nivel de refrigerante",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:19.000Z",
      "updatedAt": "2024-12-20T00:49:19.000Z"
  },
  {
      "id": 7,
      "ReporteDeServicioId": 1,
      "seccion": "MOTOR",
      "descripcion": "Control y apriete las abrazaderas del circuito refrigerante",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:19.000Z",
      "updatedAt": "2024-12-20T00:49:19.000Z"
  },
  {
      "id": 8,
      "ReporteDeServicioId": 1,
      "seccion": "MOTOR",
      "descripcion": "Drenar tanque de combustible",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:19.000Z",
      "updatedAt": "2024-12-20T00:49:19.000Z"
  },
  {
      "id": 9,
      "ReporteDeServicioId": 1,
      "seccion": "MOTOR",
      "descripcion": "Comprobar y ajustar escape",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:19.000Z",
      "updatedAt": "2024-12-20T00:49:19.000Z"
  },
  {
      "id": 10,
      "ReporteDeServicioId": 1,
      "seccion": "MOTOR",
      "descripcion": "Control y apriete de entrada de aire y conectores",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:19.000Z",
      "updatedAt": "2024-12-20T00:49:19.000Z"
  },
  {
      "id": 11,
      "ReporteDeServicioId": 1,
      "seccion": "FILTROS DE AIRE",
      "descripcion": "Compruebe el indicador del filtro de aire",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:19.000Z",
      "updatedAt": "2024-12-20T00:49:19.000Z"
  },
  {
      "id": 12,
      "ReporteDeServicioId": 1,
      "seccion": "FILTROS DE AIRE",
      "descripcion": "Limpiar el deposito del filtro de aire",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:19.000Z",
      "updatedAt": "2024-12-20T00:49:19.000Z"
  },
  {
      "id": 13,
      "ReporteDeServicioId": 1,
      "seccion": "FILTROS DE AIRE",
      "descripcion": "Cambiar los filtros principales de aire",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:19.000Z",
      "updatedAt": "2024-12-20T00:49:19.000Z"
  },
  {
      "id": 14,
      "ReporteDeServicioId": 1,
      "seccion": "FILTROS DE AIRE",
      "descripcion": "Cambiar los filtros secundarios de aire",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:20.000Z",
      "updatedAt": "2024-12-20T00:49:20.000Z"
  },
  {
      "id": 15,
      "ReporteDeServicioId": 1,
      "seccion": "FILTROS DE AIRE",
      "descripcion": "Comprobar y ajustar el cinturon porta filtro",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:20.000Z",
      "updatedAt": "2024-12-20T00:49:20.000Z"
  },
  {
      "id": 16,
      "ReporteDeServicioId": 1,
      "seccion": "ACCESORIOS",
      "descripcion": "Comrpobar KIT de Herramientas",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:20.000Z",
      "updatedAt": "2024-12-20T00:49:20.000Z"
  },
  {
      "id": 17,
      "ReporteDeServicioId": 1,
      "seccion": "ACCESORIOS",
      "descripcion": "Comprobar manuales de partes /OyM",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:20.000Z",
      "updatedAt": "2024-12-20T00:49:20.000Z"
  },
  {
      "id": 18,
      "ReporteDeServicioId": 1,
      "seccion": "FRENOS",
      "descripcion": "Chequear el estado de los frenos",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:20.000Z",
      "updatedAt": "2024-12-20T00:49:20.000Z"
  },
  {
      "id": 19,
      "ReporteDeServicioId": 1,
      "seccion": "FRENOS",
      "descripcion": "Reemplazar liquido de frenos",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:20.000Z",
      "updatedAt": "2024-12-20T00:49:20.000Z"
  },
  {
      "id": 20,
      "ReporteDeServicioId": 1,
      "seccion": "FRENOS",
      "descripcion": "Apriete de las ruedas",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:20.000Z",
      "updatedAt": "2024-12-20T00:49:20.000Z"
  },
  {
      "id": 21,
      "ReporteDeServicioId": 1,
      "seccion": "FRENOS",
      "descripcion": "Comprobar el estado de los neumáticos",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:21.000Z",
      "updatedAt": "2024-12-20T00:49:21.000Z"
  },
  {
      "id": 22,
      "ReporteDeServicioId": 1,
      "seccion": "CABINA",
      "descripcion": "Comprobar el funcionamiento de todos los instrumentos",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:21.000Z",
      "updatedAt": "2024-12-20T00:49:21.000Z"
  },
  {
      "id": 23,
      "ReporteDeServicioId": 1,
      "seccion": "CABINA",
      "descripcion": "Comprobar ruidos inusuales",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:21.000Z",
      "updatedAt": "2024-12-20T00:49:21.000Z"
  },
  {
      "id": 24,
      "ReporteDeServicioId": 1,
      "seccion": "CABINA",
      "descripcion": "Comrprobar el funcionamiento de todos los controles de operación",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:21.000Z",
      "updatedAt": "2024-12-20T00:49:21.000Z"
  },
  {
      "id": 25,
      "ReporteDeServicioId": 1,
      "seccion": "CABINA",
      "descripcion": "Comprobar y ajustar las palancas de operación",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:21.000Z",
      "updatedAt": "2024-12-20T00:49:21.000Z"
  },
  {
      "id": 26,
      "ReporteDeServicioId": 1,
      "seccion": "TRANSMISIÓN",
      "descripcion": "Chequear nivel de aceite de transmisión",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:21.000Z",
      "updatedAt": "2024-12-20T00:49:21.000Z"
  },
  {
      "id": 27,
      "ReporteDeServicioId": 1,
      "seccion": "TRANSMISIÓN",
      "descripcion": "Reemplazar aceite de transmisión",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:21.000Z",
      "updatedAt": "2024-12-20T00:49:21.000Z"
  },
  {
      "id": 28,
      "ReporteDeServicioId": 1,
      "seccion": "TRANSMISIÓN",
      "descripcion": "Comprobar el nivel de aceite de diferenciales",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:22.000Z",
      "updatedAt": "2024-12-20T00:49:22.000Z"
  },
  {
      "id": 29,
      "ReporteDeServicioId": 1,
      "seccion": "TRANSMISIÓN",
      "descripcion": "Reemplazar aceite de diferenciales",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:22.000Z",
      "updatedAt": "2024-12-20T00:49:22.000Z"
  },
  {
      "id": 30,
      "ReporteDeServicioId": 1,
      "seccion": "TRANSMISIÓN",
      "descripcion": "Reemplazar aceite del convertidor par",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:22.000Z",
      "updatedAt": "2024-12-20T00:49:22.000Z"
  },
  {
      "id": 31,
      "ReporteDeServicioId": 1,
      "seccion": "TRANSMISIÓN",
      "descripcion": "Comprobar el estado de eje de tracción",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:22.000Z",
      "updatedAt": "2024-12-20T00:49:22.000Z"
  },
  {
      "id": 32,
      "ReporteDeServicioId": 1,
      "seccion": "ENGRRASE",
      "descripcion": "Realizar servicio de engrase en todos los puntos indicados por el manual de OyM",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:22.000Z",
      "updatedAt": "2024-12-20T00:49:22.000Z"
  },
  {
      "id": 33,
      "ReporteDeServicioId": 1,
      "seccion": "SIST. ELÉCTRICO",
      "descripcion": "Comprobar el estado de las baterías",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:22.000Z",
      "updatedAt": "2024-12-20T00:49:22.000Z"
  },
  {
      "id": 34,
      "ReporteDeServicioId": 1,
      "seccion": "SIST. ELÉCTRICO",
      "descripcion": "Comprobar luces",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:22.000Z",
      "updatedAt": "2024-12-20T00:49:22.000Z"
  },
  {
      "id": 35,
      "ReporteDeServicioId": 1,
      "seccion": "SIST. ELÉCTRICO",
      "descripcion": "Comprobar alarma / bocina",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:22.000Z",
      "updatedAt": "2024-12-20T00:49:22.000Z"
  },
  {
      "id": 36,
      "ReporteDeServicioId": 1,
      "seccion": "CHÁSIS",
      "descripcion": "Ajuste de todos los pernos y bulones",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:23.000Z",
      "updatedAt": "2024-12-20T00:49:23.000Z"
  },
  {
      "id": 37,
      "ReporteDeServicioId": 1,
      "seccion": "CHÁSIS",
      "descripcion": "Chquear perdida de aceite",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:23.000Z",
      "updatedAt": "2024-12-20T00:49:23.000Z"
  },
  {
      "id": 38,
      "ReporteDeServicioId": 1,
      "seccion": "CHÁSIS",
      "descripcion": "Chequear estribos y perifericos de estructura",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:23.000Z",
      "updatedAt": "2024-12-20T00:49:23.000Z"
  },
  {
      "id": 39,
      "ReporteDeServicioId": 1,
      "seccion": "TORRE DE TRABAJO",
      "descripcion": "Comprobar el nivel de aceite hidráulico",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:23.000Z",
      "updatedAt": "2024-12-20T00:49:23.000Z"
  },
  {
      "id": 40,
      "ReporteDeServicioId": 1,
      "seccion": "TORRE DE TRABAJO",
      "descripcion": "Chequear el estado de las cadenas",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:26.000Z",
      "updatedAt": "2024-12-20T00:49:26.000Z"
  },
  {
      "id": 41,
      "ReporteDeServicioId": 1,
      "seccion": "TORRE DE TRABAJO",
      "descripcion": "Reemplzar aceite hidráulico",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:26.000Z",
      "updatedAt": "2024-12-20T00:49:26.000Z"
  },
  {
      "id": 42,
      "ReporteDeServicioId": 1,
      "seccion": "TORRE DE TRABAJO",
      "descripcion": "Comprobar el estado de la torre y rodamiento",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:26.000Z",
      "updatedAt": "2024-12-20T00:49:26.000Z"
  },
  {
      "id": 43,
      "ReporteDeServicioId": 1,
      "seccion": "TORRE DE TRABAJO",
      "descripcion": "Comprobar el estado de las cadenas",
      "condicion": "Buena",
      "createdAt": "2024-12-20T00:49:26.000Z",
      "updatedAt": "2024-12-20T00:49:26.000Z"
  }
]