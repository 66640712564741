import React from 'react';
import { Box, Grid, TextField, Typography , Button} from '@mui/material'
import { styleBox } from "../components/utils/Stylebox";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import swal from "sweetalert";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import SendIcon from "@mui/icons-material/Send";
import api from "../components/utils/api";


dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("es");
const CargarHoras = () => {
    const [ot, setOt] = useState("")
    const [serie, setSerie] = useState("")
    const [descripcion, setDescripcion] = useState("")
    const [fecha, setFecha] = React.useState(dayjs().tz(dayjs.tz.guess()));
    const [horaFin, setHoraFin] = React.useState(dayjs());
    const [horaInicio, setHoraInicio] = React.useState(dayjs());
    const [orden, setOrden] = useState([]);
    const [loading, setLoading] = useState(false);
    const userToken = localStorage.getItem("user-token");
    const user = localStorage.getItem("nombre-Completo");
    const navigate = useNavigate();
    const urlHoras = api.ordenesHoras
    const urlOrdenes = api.ordenes;

    const ListarServicios = async ( serie) => {
      try {
        const headers = { "iron-token": userToken };
        setLoading(true);
        let res = await fetch(`${urlOrdenes}serie/${serie}`, { headers });
        setLoading(false);
        if (res.ok) {
          let data = await res.json();
          setOrden(data);
        } else {
          setLoading(false);
      
        }
      } catch (error) {
        setLoading(false);
        swal("Error", error.message, "error");
      }
    };

    const cargarHoras = async ()=>{
        try {
            const total = Math.abs(new Date(horaFin) - new Date(horaInicio)) / 36e5 // Cálculo de diferencia en horas
            console.log(total)
          
            const confirmacion = await swal({
              title: `¿Estás  seguro? se asignaran ${total} horas al usuario ${user}`,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
      
            if (!confirmacion) {
              return; // No se realiza ninguna acción adicional si el usuario cancela
            }
            if(total === 0 ){
                swal("Error", "debes cargar horas", "error");
                return
            }
            if(ot === "" ){
                swal("Error", " orden de trabajo es requerido", "error");
                return
            }
            const requestOptions = {
              method: "POST",
              body: JSON.stringify({
                ordenTrabajo: ot,
                usuario: user,
                fecha: fecha,
                horaInicio: horaInicio,
                horaFin: horaFin,
                totalHoras: total

           
           
              }),
             
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                "iron-token": userToken,
              },
            };
      
              setLoading(true);
              const response = await fetch(urlHoras, requestOptions);
              setLoading(false);
              swal({
                title: "orden creada!",
                icon: "success",
                showConfirmButton: false,
                timer: 4000,
              });
              if(response.status === 201){
                navigate("/ordenes");
              }
              
            
          } catch (err) {
            swal({
              title: "Ocurrió un error, contactar con el administrador",
              icon: "error",
              showConfirmButton: false,
              timer: 4000,
            });
          } finally {
            setLoading(false);
          }
    }
  return (
    <div>
          <Box sx={styleBox}>
          <Typography
            variant="h6"
            color="white"
            align="center"
            pb={1}
            marginBottom={"20px"}
            style={{
              backgroundColor: "#161616",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            Cargar horas a orden de trabajo
          </Typography>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="serie"
                  label="Serie"
                  variant="outlined"
                  fullWidth
                  required
                  value={serie}
                  onChange={(e)=>{setSerie(e.target.value)}}
                  onBlur={(e)=>{ListarServicios(e.target.value)}}
                
                />
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateField
                    label="Fecha"
                    value={fecha}
                    onChange={(newValue) => setFecha(newValue)}
                    fullWidth
                    required
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={6}>
              <TextField
                  id="ot"
                  label="Ot"
                  variant="outlined"
                  fullWidth
                  required
                  value={ot}
                  onChange={(e)=>{setOt(e.target.value)}}
                
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Hora inicio de la tarea"
                  value={horaInicio}
                  onChange={(newValue) => setHoraInicio(newValue)}
                  required
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Hora fin de la tarea"
                  value={horaFin}
                  onChange={(newValue) => setHoraFin(newValue)}
                  required
                />
              </LocalizationProvider>
            </Grid>
           
            </Grid>
            <TextField
              id="falla"
              label="Descripción del trabajo realizado"
              multiline
              fullWidth
              minRows={3}
              maxRows={10}
              value={descripcion}
              onChange={(e)=>{setDescripcion(e.target.value)}}
              required
        />
          </Box>
          <Box sx={styleBox}>
        <Button
          variant="contained"
          fullWidth
          color="success"
          endIcon={<SendIcon />}
          style={{
            marginTop: "20px",
          }}
          type="submit"
          onClick={() => {
            cargarHoras()
          }}
        >
          asignar horas
        </Button>
      </Box>
        </Box>
    </div>
  )
}

export default CargarHoras
