import React from 'react';
import {  TextField, Typography, Grid, Box, Select, MenuItem } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AdjustIcon from '@mui/icons-material/Adjust';

const Cabecera = ({ mode, condiciones, handleInputChange, selectedCondition, handleSelectChange }) => {
  return (
    <>
      <Box padding={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id="modelo"
              label="Modelo"
              variant="outlined"
              fullWidth
              value={condiciones.modelo}
              onChange={handleInputChange}
              disabled={mode === 'view'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id="chasis"
              label="N° Chásis"
              variant="outlined"
              fullWidth
              required
              value={condiciones.chasis}
              onChange={handleInputChange}
              disabled={mode === 'view'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id="serie"
              label="N° Serie"
              variant="outlined"
              fullWidth
              required
              value={condiciones.serie}
              onChange={handleInputChange}
              disabled={mode === 'view'}
            />
          </Grid>
        </Grid>
        <Box sx={{ padding: 2 }}>
          <Typography variant="h6">Selecciona tipo de transmisión:</Typography>
          <Select
            value={selectedCondition}
            onChange={handleSelectChange}
            displayEmpty
            fullWidth
            disabled={mode === 'view'}
          >
            <MenuItem value="M"><em>T/C</em></MenuItem>
            <MenuItem value="C">T/M</MenuItem>
          </Select>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id="modeloMotor"
              label="Modelo Mortor"
              variant="outlined"
              fullWidth
              value={condiciones.modeloMotor}
              onChange={handleInputChange}
              disabled={mode === 'view'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id="serieMotor"
              label="N° Serie Mortor"
              variant="outlined"
              fullWidth
              value={condiciones.serieMotor}
              onChange={handleInputChange}
              disabled={mode === 'view'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id="modeloMotor1"
              label="Modelo"
              variant="outlined"
              fullWidth
              required
              value={condiciones.modeloMotor1}
              onChange={handleInputChange}
              disabled={mode === 'view'}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id="serieMotor1"
              label="N° Serie"
              variant="outlined"
              fullWidth
              value={condiciones.serieMotor1}
              onChange={handleInputChange}
              disabled={mode === 'view'}
            />
          </Grid>
     
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id="horometro"
              label="Horómetro"
              variant="outlined"
              fullWidth
              required
              value={condiciones.horometro}
              onChange={handleInputChange}
              disabled={mode === 'view'}
            />
          </Grid>
        </Grid>
        <Typography variant="body2" mt={2}>
          *Nota: A continuación se presentan los elementos relacionados con la operación, mantenimiento y lubricación...
        </Typography>
        <Box display="flex" mt={2}>
          <Box display="flex" alignItems="center" mr={2}>
            <CheckCircleIcon color="primary" />
            <Typography variant="body2" ml={1}>Condición: Buena</Typography>
          </Box>
          <Box display="flex" alignItems="center" mr={2}>
            <CancelIcon color="error" />
            <Typography variant="body2" ml={1}>Condición: Mala</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <AdjustIcon color="action" />
            <Typography variant="body2" ml={1}>Corrección Efectuada</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Cabecera;